<template>
  <p class="custom-option__title">
    {{ $t('dashboard.filters.time.types.absolute') }}
  </p>
  <button
    class="custom-option__element"
    :class="isSelected && 'custom-option__element--selected'"
    @click="toggleCustomPeriodModal"
  >
    <i
      class="element__period-icon"
      :class="`icon-pencil`"
    />
    <span class="element__label">
      {{ $t('dashboard.filters.time.choices.custom') }}
    </span>
    <i
      v-if="isSelected"
      class="element__success-icon"
      :class="`icon-success`"
    />
  </button>
  <Teleport
    v-if="isCustomPeriodModalOpen"
    to="#action"
  >
    <ReflectModal
      :title-message="$t('dashboard.filters.time.customPeriodModal.title')"
      :action-message="
        inputDateRange.length > 0
          ? $t('dashboard.filters.time.customPeriodModal.save')
          : undefined
      "
      @action="validateCustomChoice"
      @cancel="toggleCustomPeriodModal"
    >
      <ReflectSelectDateRangePeriodSelection
        :initial-value="dateRange"
        @update-value="updateSelectedDateRange"
      />
    </ReflectModal>
  </Teleport>
</template>

<script lang="ts">
import { formatDate2 } from '~/services/date'

export default {
  name: 'CustomOption',
  props: {
    isSelected: {
      type: Boolean,
      required: true
    },
    dateRange: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  emits: ['set-date-range'],
  data(): {
    isCustomPeriodModalOpen: boolean
    inputDateRange: string[]
  } {
    return {
      isCustomPeriodModalOpen: false,
      inputDateRange: []
    }
  },
  methods: {
    validateCustomChoice() {
      this.isCustomPeriodModalOpen = false
      const inputDateRange = [
        formatDate2(this.inputDateRange[0]!),
        formatDate2(this.inputDateRange[1]!)
      ]

      if (
        this.dateRange[0] !== inputDateRange[0] ||
        this.dateRange[1] !== inputDateRange[1]
      ) {
        this.$emit('set-date-range', inputDateRange)
      }
    },
    toggleCustomPeriodModal() {
      this.isCustomPeriodModalOpen = !this.isCustomPeriodModalOpen
    },
    updateSelectedDateRange(newValue: string[]) {
      this.inputDateRange = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-option {
  &__title {
    @include font-text;
    @include font-size($font-size-mini);
    color: $text-tertiary;
    margin-bottom: 4px;

    &:not(:first-of-type) {
      margin-top: $margin-small;
    }
  }

  &__element {
    display: flex;
    align-items: center;
    padding: 4px 0;
    color: $link-secondary;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &--selected,
    &:hover {
      color: $text-primary;
    }

    .element {
      &__period-icon {
        @include font-icon;
        @include font-size($font-size-intermediate);
        margin-right: 4px;
      }

      &__label {
        @include font-text;
        @include font-size($font-size-regular);
      }

      &__success-icon {
        @include font-icon;
        @include font-size(18px);
        margin-left: auto;
      }
    }
  }
}
</style>
